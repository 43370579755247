import React, { useCallback, useEffect } from "react";
import { supplementsLink, scheduleLink, mobileWidth }  from "../config";
import { Parallax } from "react-parallax";

// reactstrap components
import { Button, Container } from "reactstrap";

import { trackButtonClick } from './GoogleAnalytics';

// css
import "./NaturallyAlignedHeader.css";

// images
import bg1 from "../assets/img/bg1-2000.jpg";
import bgMobile from "../assets/img/bg1-mobile.jpg";
import bg2 from "../assets/img/bg2-2000.jpg";
import naLogo from "../assets/img/na-logo-300-gray.png";

function NaturallyAlignedHeader() {
  let logoDivRef = React.createRef();
  let logoRef = React.createRef();

  let parallaxStrength = 400;
  let backgroundImage;
  if(window.innerWidth < mobileWidth) {
    backgroundImage = bgMobile;
    parallaxStrength = 200;
  } else {
    if(Math.floor(Math.random() * 2)) {
      backgroundImage = bg1;
    } else {
      backgroundImage = bg2;
    }
  }

  const getLogoDimension = useCallback(() => {
    let width = logoDivRef.current ? logoDivRef.current.offsetWidth : 300;
    if (width > 400) {
      return 300 + (width - 400);
    } 
  }, [logoDivRef]);

  useEffect(() => {
    if(!logoDivRef) {
      return;
    }
    let dimension = getLogoDimension();
    logoRef.current.style.width = `${dimension}px`;
    logoRef.current.style.height = `${dimension}px`;
  }, [logoDivRef, logoRef, getLogoDimension]);

  useEffect(() => {
    const handleResize = () => {
      let width = logoDivRef.current ? logoDivRef.current.offsetWidth : 300;
      if (width > 400) {
        let dimension = getLogoDimension();
        logoRef.current.style.width = `${dimension}px`;
        logoRef.current.style.height = `${dimension}px`;
      }
    }
    window.addEventListener('resize', handleResize);
    
    return function cleanup()  {
      window.removeEventListener('resize', handleResize);
    };
  });
  
  return (
    <>
      <Parallax bgClassName="page-header-image na-page-header-image"
                  bgImage={backgroundImage}
                  strength={parallaxStrength}>
        <div className="na-page-header page-header page-header-small">
          <div className="na-lead-logo" ref={logoDivRef}>
            <img alt="Naturally Aligned"
              ref={logoRef} 
              style={{ 
                width: getLogoDimension() + "px",
                height: getLogoDimension() + "px",
              }}
              src={ naLogo } />
          </div>
          <div className="na-content-center content-center">
          <Container className="na-above-fold-content">
            <div className="na-small-logo">
              <img alt="Naturally Aligned" src={ naLogo } />
            </div>
            <h1 className="na-lead">
              Chiropractic Adjustments<br/>
              for Moms &amp; Babies
            </h1>
            <h3 className="na-lead">
              <div className="na-lead-emphasis">
                empowering&nbsp;
              </div>
              moms growing strong, healthy, vibrant families
            </h3>
            <div>
              <Button className="na-header-button"
                      target="_blank" rel="noopener noreferrer"
                      onClick={() => trackButtonClick("Schedule Appointment Top Center")}
                      href={scheduleLink}>
                Schedule an Appointment
              </Button>

              <Button className="na-header-button"
                      target="_blank" rel="noopener noreferrer"
                      onClick={() => trackButtonClick("Order Supplements")}
                      href={supplementsLink}>
                Order Supplements
              </Button>
            </div>
            <div className="na-tagline">
              <h1>
                Life is Better When You're Aligned
              </h1>
            </div>
          </Container>
          </div>
        </div>
      </Parallax>
    </>
  );
}

export default NaturallyAlignedHeader;
