import React from "react";

// reactstrap components
import {
	Container,
	Row,
	Col
} from "reactstrap";

// css
import "./MaternalSection.css";

// images 
import icon1 from "../assets/img/maternal-icon1.jpg";
import icon2 from "../assets/img/maternal-icon2.jpg";
import icon3 from "../assets/img/maternal-icon3.jpg";
import icon4 from "../assets/img/maternal-icon4.jpg";

function MaternalSection() {
	return(
		<>
		<div className="section na-section-maternal">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto na-maternal">
                No matter how you are planning to birth your baby, <br />
				<div className="na-maternal-emphasis">
					Naturally Aligned&nbsp;
				</div>
				is here to help you arrive comfortably.
              </Col>
            </Row>
			<Row className="na-maternal-blocks">
				<Col className="ml-auto mr-auto">
					<img alt="Effective Adjustments" src={icon1}/>
					<div className="na-block-title">
						Effective<br/>
						Adjustments
					</div>
					<div className="na-maternal-block-content">
						We work to open and balance your body.
					</div>
				</Col>
				<Col className="ml-auto mr-auto">
					<img alt="Lifestyle Guidance" src={icon2}/>
					<div className="na-block-title">
						Lifestyle<br />
						Guidance
					</div>
					<div className="na-maternal-block-content">
						Advice on optimal sleep, breathing, breastfeeding and more.
					</div>
				</Col>
				<Col className="ml-auto mr-auto">
					<img alt="Supplement Recommandations" src={icon3}/>
					<div className="na-block-title">
						Supplement<br />
						Recommendations
					</div>
					<div className="na-maternal-block-content">
						Quality products, based on your nutritional needs.
					</div>
				</Col>
				<Col className="ml-auto mr-auto">
					<img alt="Simple Stretches" src={icon4}/>
					<div className="na-block-title">
						Simple<br />
						Stretches
					</div>
					<div className="na-maternal-block-content">
						Movement to get and keep you comfortable.
					</div>
				</Col>
			</Row>
          </Container>
        </div>
		</>
	);
}

export default MaternalSection;