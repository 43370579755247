import React from 'react';

import NaturallyAlignedNavbar from "./components/NaturallyAlignedNavbar.js";
import NaturallyAlignedFooter from "./components/NaturallyAlignedFooter.js";
import StaffBios from './components/StaffBios.js';

function AboutUs() {
	React.useEffect(() => {
		window.scrollTo(0,0);
		document.body.classList.add("na-dark-background");
		return function cleanup() {
			document.body.classList.remove("na-dark-background");
		};
	});
	
	return (
		<>
			<NaturallyAlignedNavbar toggle={true} />

			<div className="wrapper">

				<div className="na-navMenuBackground">
				</div>

				<StaffBios />

  				<NaturallyAlignedFooter />
  
			</div>
			
		</>
	);
}

export default AboutUs;