import React, { useState } from "react";

import { TestimonialData } from './TestimonialsData';
import { 
	Carousel,
	CarouselItem,
	Container
} from "reactstrap";

import './Testimonials.css';

function Testimonials(props) {
	const [activeIndex, setActiveIndex] = useState(0);

	const next = () => {
		const nextIndex = activeIndex === TestimonialData.length - 1 ? 0 : activeIndex + 1;
		setActiveIndex(nextIndex);
	}

	const previous = () => {
		const nextIndex = activeIndex === 0 ? TestimonialData.length - 1 : activeIndex - 1;
		setActiveIndex(nextIndex);
	}

	const slides = TestimonialData.map(item => {
		return (
			<CarouselItem key={item.id}>
				<div className="na-testimonial" 
					 dangerouslySetInnerHTML={{ __html: "&quot;" + item.content + "&quot;"}}>
				</div>
			</CarouselItem>
		);
	});

	return(
		<>
			<div className="section na-section-testimonials">
				<Container>
					<h2>What clients are saying...</h2>
					<Carousel
						className="na-testimonial-carousel"
						activeIndex={activeIndex}
						next={next}
						previous={previous}
						ride='carousel'
						interval={10000}>
						{slides}
					</Carousel>
				</Container>
			</div>
		</>
	);
}

export default Testimonials;