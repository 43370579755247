import React from "react";
import { Parallax } from "react-parallax";
import { trackButtonClick } from './GoogleAnalytics';
import {
	Button,
	Container,
	Row,
	Col
} from "reactstrap";

// css
import "./DownloadGuide.css";

// image
import backgroundImage from "../assets/img/download-guide-2000.jpg";

function DownloadGuide() {
	return(
		<>
			<Parallax bgClassName="na-download-guide-image" 
					  bgImage={backgroundImage} 
					  strength={400}>
				<Container className="na-download-guide"> 
					<Row>
						<Col sm="6" className="na-download-guide-heading">
							Request this free guide: Surviving the Most Common Complaints of Pregnancy, Naturally.
						</Col>
					</Row>
					<Row>
						<Col>
							Are you new to chiropractic care?  
							Or are you experiencing pain you are unsure that chiropractic care can help with?
							Then this guide is for you!
						</Col>
					</Row>
					<Row>
						<Col>
							Inside you'll learn:
						</Col>
					</Row>
					<Row>
						<Col>
							<ul>
								<li>The most common complaints we hear about from pregnant mamas.</li>
								<li>Natural, at-home suggestions for even a novice.</li>
								<li>If and how chiropractic care may help you.</li>
							</ul>
						</Col>
					</Row>
					<Row>
						<Col sm="6" md={{ size: 5, offset: 1 }}>
							<Button onClick={() => {
								trackButtonClick("Download Guide");
								window.top.location="mailto:info@naturallyaligned.com?subject=Request Surviving the Most Common Complaints of Pregnancy, Natrually";
							}}
									className="na-download-guide-button"
									size="lg" block>
								Request Guide
							</Button>
						</Col>
					</Row>
				</Container>
			</Parallax>
		</>
	)
}

export default DownloadGuide;