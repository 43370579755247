module.exports = {
	scheduleLink: "https://app.acuityscheduling.com/schedule.php?owner=15268873c",
	supplementsLink: "https://us.fullscript.com/welcome/amoravec",
	facebookLink: "https://www.facebook.com/pages/Naturally-Aligned-Family-Chiropractic/176567518114?ref=hl",
	instagramLink: "https://www.instagram.com/naturallyaligned",
	googleAnalyticsCodeProd: "G-YGK1LYQPVN",
	googleAnalyticsCodeDev: "G-P6P8X217B5",
	eaganMapLink: "https://goo.gl/maps/HU5fWxqXAKJ3XMzS7",
	wblMapLink: "https://goo.gl/maps/C4hKrLRvRWgt8Umk6",
	stpMapLink: "https://goo.gl/maps/eBzPi4ETHCTa1M9P6",
	mobileWidth: 415
}