
const TestimonialData = [
	{
		id: 1,
		content: `I'm feeling much better. When I worked for NCMIC the president always talked about the
				  benefits of chiropractic care & how just the smallest adjustment w/your hands can make
				  such a huge difference. As I'd never seen a chiropractor, I was always a bit skeptical
				  about what he was saying...but NOW, now I totally understand. Not only is my sacrum 
				  feeling much better, but I'm also much less nauseous than before. I remember you saying
				  the adjustments you did on my neck may help me feel better. Thanks for all your help! 
				  I'm looking forward to my next adventure in the world of chiropractic!`
	},
	{
		id: 2,
		content: `Because I started chiropractic care early on in the pregnancy I will never know how 
				  it would have been different without it. You read my body well and always seemed to keep
				  me in good alignment. You often felt and pinpointed areas of my body that bothered me 
				  without my even saying it. I ended up with back labor, but was able to deliver intervention 
				  free in a seven-hour labor and delivery. I believe having my pelvic area in alignment 
				  reduced the amount of time it took to push and deliver my daughter. Chiropractic care is
				  an investment in your and your baby's life. Naturally Aligned is totally committed your 
				  well-being and is a wealth of knowledge and experience, they are incredibly supportive 
				  and shares in the joys (and not joyful areas) of pregnancy. Naturally Aligned provides
				  great care at a great value.`				  
	},
	{
		id: 3,
		content: `I wouldn't have given birth without you! Seriously I'm pretty sure the little 
				  guy would be stuck in there if you hadn't 'adjusted&paos; him out! If it wasn't 
				  for the adjustment I had during the last phase of my labor I don't think I would've
				  been able to push my son out myself. You allowed me to not only find a second wind to finish 
				  labor but also find a way to finishing pushing as well. Beyond labor I feel immediate relief
				  after each treatment. I've been dealing with the neck pain all my life and you are the
				  first chiropractor (I've seen many!!) to eliminate the pain and I only have to see you
				  once per month to keep the pain away. That has never happened before! I remember the first
				  adjustment being so gentle and comfortable I have never had such an 'easy' adjustment. 
				  I felt comfortable with your techniques and your bedside manner.`
	},
	{
		id: 4,
		content: `Your care has changed the way I look at health care! It has opened my eyes to so many things!
				  I will forever be changed by chiropractic care and will continue to keep it in my life and in 
				  my family's life! THANK YOU!!!`
	},
	{
		id: 5,
		content: `Having you adjust me during my labor was amazing. Once you showed up and started adjusting me, 
				  I started to progress more quickly though the labor than I had been. You helped greatly during
				  my contractions knowing just the right places to apply counter pressure. Before that I had not
				  even wanted anyone to touch me. For the health of you and your baby during pregnancy and labor
				  it is so important to have chiropractic care. It makes you feel so much better with all the little
				  and big aches & pains that your changing pregnant body experiences.`
	},
	{
		id: 6,
		content: `I believe your care helped move my baby in the correct position to be born head down; you did an 
				  awesome job of talking me through not only the chiropractic side of my experience, but also the 
				  emotional aspect. You were very supportive; it really calmed my nerves and put my mind in the 
				  right place to help move my baby. Thank You! I promote chiropractic care to anyone I know who 
				  is pregnant as well as trying to get pregnant.`
	},
	{
		id: 7,
		content: `After my two-month-old's first adjustment, his body was relaxed and he was not fussy in the 
				  evening (as he was previous evenings since birth). He slept for a long stretch that night, too! 
				  He continued to be happier and a more relaxed infant since his first adjustment. Wish I would
				  have taken him in sooner!`
	},
	{
		id: 8,
		content: `What can I even say? Chiropractic is our whole family's regular wellness plan. We get adjusted
				  every week, and whenever we have an extra hard fall, or have an illness coming on, we get an extra
				  visit in. My boys (ages 5, 2.5 and 8 months) love going as they've gone since their first week of
				  life! We get sick some, but recover quickly. These Doctors have become my sounding board for all 
				  health related things as well as just life! Naturally Aligned Chiropractic & Wellness is like a 
				  second home with a comfy and welcoming atmosphere. Never hurried or rushed, but welcomed, listened
				  to and never judged.`
	},
	{
		id: 9,
		content: `I am so thankful for the chiropractic care I received at Naturally Aligned during my last pregnancy! 
				  With all my pregnancies I have had considerable back and hip pain but not until this time, pregnant
				  with my fourth, did I go to a chiropractor. It made all the difference. At weeks 15-22, I was having
				  so much pain, everything I did hurt, and sleeping at night was impossible. After I started at 
				  Naturally Aligned I felt so much better! I could walk without pain and my back never bothered me
				  again throughout the pregnancy. I kept waiting for the back pain to come towards the end of my pregnancy, 
				  but it never did. I know it is because of the care I received at Naturally Aligned, and I am so thankful 
				  and appreciative of the gift they gave me in not having to deal with pain like I had previously. 
				  The chiropractors here are amazing, so kind, caring and knowledgeable! My whole family loves coming! 
				  It has been a huge blessing to us! Thank you!!`
	}
];

export { TestimonialData };