import React from "react";

// reactstrap components
import {
	Container,
	Row,
	Col
} from "reactstrap";

// css
import "./ThreeServices.css";

// images
import photo1 from "../assets/img/hands-on-belly-360.jpg";
import photo2 from "../assets/img/baby-on-back-360.jpg";
import photo3 from "../assets/img/child-on-parent-on-table-360.jpg";

function ThreeServices() {
	return (
		<>
			<Container id="services" className="na-three-services">
				<Row>
					<Col lg className="na-three-services-col">
						<img alt="Hands on belly" src={photo1} />
						<p/>
						<div className="na-three-services-title">
							Perinatal Care
						</div>
						From pregnancy planning to postpartum recovery, specialized chiropractic care enables you to experience 
						the wonders of this journey without unnecessary medical interventions. Studies have shown that woman who
						receive consistent chiropractic care may conceive easier; have shorter, less painful labors, with fewer 
						complications or interventions and recover more rapidly. Our experience confirms that while labor can 
						certainly be intense you can be comfortable and confident throughout this journey. Chiropractic techniques 
						are different for pregnant women and we are specially trained to provide the safest perinatal care. 
					</Col>
					<Col lg className="na-three-services-col">
						<img alt="Baby getting adjusted" src={photo2} />
						<p />
						<div className="na-three-services-title">
							Pediatric Care
						</div>
						The 4 T's (toxins, traumas, thoughts and technology) can cause all body's to malfunction, but kids growing
						bodies are most susceptible. The birth process, learning to walk and growing in general can impact the 
						function of their spine. Spinal misalignments are not as obvious as a "check engine" light. These are the 
						kiddos who seem inflexible (physically and in their disposition), need frequent antibiotics, fall often or
						don't sleep well. The earlier children’s spines are checked, the earlier problems can be fixed to prevent 
						further health problems from occurring. Our Doctors use the gentlest touch and make getting adjusted fun!
					</Col>
					<Col lg className="na-three-services-col">
						<img alt="Child on dad" src={photo3} />
						<p />
						<div className="na-three-services-title">
							Family Wellness
						</div>
						Regular  chiropractic care can help maintain your family's busy routine with greater ease, especially 
						when combined with other healhty activities such as regular exercise and eating a nutritious diet. Improved 
						immune function, fewer sick days, better mental clarity, better digestive function are all things people 
						who receive care in our office rave about. Your brain communicates through your body's nervous system,
						sending messages via your spinal column, so it's easy to see how taking care of this conduit is vital 
						to every single aspect of your life. Routine chiropractic adjustments are key to good overall health and 
						can have a significant positive impact on your well-being.
					</Col>
				</Row>
			</Container>
		</>
	)
}

export default ThreeServices;