import React from 'react';

import NaturallyAlignedNavbar from "./components/NaturallyAlignedNavbar.js";
import NaturallyAlignedFooter from "./components/NaturallyAlignedFooter.js";
import FAQList from './components/FAQList.js';

function FAQs() {
	React.useEffect(() => {
		window.scrollTo(0,0);
		document.body.classList.add("na-dark-background");
		return function cleanup() {
			document.body.classList.remove("na-dark-background");
		};
	});
	
	return (
		<>
			<NaturallyAlignedNavbar toggle={true} />

			<div className="wrapper">

				<div className="na-navMenuBackground">
				</div>

				<FAQList />

  				<NaturallyAlignedFooter />
  
			</div>
			
		</>
	);
}

export default FAQs;