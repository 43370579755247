import React from 'react';

// reactstrap components
import {
	Container,
	Row,
	Col
} from "reactstrap";

// css
import "./StaffBios.css";

// images
import drAmberHeadshot from "../assets/img/dr-amber-headshot.jpg";
import drKelleyHeadshot from "../assets/img/dr-kelley-headshot.jpg";
import drKatieHeadshot from "../assets/img/dr-katie-headshot.jpg";
import divider from "../assets/img/scratch.png";

function StaffBios() {
	return(
		<>
			<div className="na-staff-bios">
				<Container>
					<div className="na-staff-bios-header">
						Our Staff
					</div>
					<Row>
						<Col lg="10">
							<div className="na-staff-bios-lead">
							Our team is committed to helping busy moms like you invest in caring for your body and health,
							whatever that may mean to you. We want to take pain or worrying off of your to do list so you 
							and your family can thrive!
							</div>
						</Col>
						<Col>
						</Col>
					</Row>
					<Row>
						<Col className="na-bio-col">
							<img className="na-bio-headshot" alt="Dr. Amber" src={drAmberHeadshot} />
							<div className="na-bio-name">Dr. Amber Moravec</div>
							<div className="na-bio-title">Founder, Chiropractor</div>
							<div className="na-bio-divider">
								<img src={divider} alt="divider" />
							</div>
							<div className="na-bio-contact">
								<a className="na-bio-contact-link" href="mailto:dramber@naturallyaligned.com">dramber@naturallyaligned.com</a>
							</div>
							<div className="na-bio">
								Dr. Amber grew up in southern Minnesota and attended the University of Minnesota Duluth 
								graduating with a degree in Elementary Education. A change of heart and growing interest 
								in natural healthcare inspired her to pursue a doctorate degree in chiropractic. She graduated 
								from Northwestern Health Sciences University and has spent her entire career 
								working with the perinatal population and helping families achieve their wellness goals while 
								raising healthy, un- medicated children.  Dr. Amber has advance training in lactation, Kinesiotaping, 
								craniosacral therapy and the Webster Technique. She lives in Hugo with her husband, Andrew; sons, 
								Everett and Jackson and daughter, Elise.
							</div>
						</Col>
						<Col className="na-bio-col">
							<img className="na-bio-headshot" alt="Dr. Kelley" src={drKelleyHeadshot} />
							<div className="na-bio-name">Dr. Kelley Lumbard</div>
							<div className="na-bio-title">Chiropractor</div>
							<div className="na-bio-divider">
								<img src={divider} alt="divider" />
							</div>
							<div className="na-bio-contact">
								<a className="na-bio-contact-link" href="mailto:drkelley@naturallyaligned.com">drkelley@naturallyaligned.com</a>
							</div>
							<div className="na-bio">
								Dr. Kelley grew up in Apple Valley and attended the University of Wisconsin - Madison, where 
								she studied neurobiology and psychology. She was always drawn to the more natural side of 
								medicine and the use of non-invasive means of healing which led her to pursue chiropractic 
								as a career at Northwestern Health Sciences University. She has completed a post graduate 
								certificate in pediatric and perinatal chiropractic from the ICPA, as well as training in
								kinesiotape, Graston, and CST. She lives in Eagan with her husband Derek and their
								two sons, Callen, Miles, and their daughter Olivia.  In her free time, Dr. Kelley enjoys crafting, 
								cooking, spending time outdoors on her bike or in her garden.
							</div>
						</Col>
						<Col className="na-bio-col">
							<img className="na-bio-headshot" alt="Dr. Katie" src={drKatieHeadshot} />
							<div className="na-bio-name">Dr. Katie Marlowe</div>
							<div className="na-bio-title">Chiropractor</div>
							<div className="na-bio-divider">
								<img src={divider} alt="divider" />
							</div>
							<div className="na-bio-contact">
								<a className="na-bio-contact-link" href="mailto:drkatie@naturallyaligned.com">drkatie@naturallyaligned.com</a>
							</div>
							<div className="na-bio">
								Dr. Katie grew up in Blaine and attended Winona State University , graduating with a degree
								in Cell and Molecular Biology. With an interest in the health field and a desire to help 
								people naturally, she went on to attend chiropractic school at National University of Health 
								Sciences. Dr. Katie has a strong passion for helping the perinatal and pediatric population. 
								She enjoys helping provide emotional support, guidance, pain relief, as well as encouragement
								to mothers to trust in their own body’s ability to function naturally. Dr. Katie has training 
								in Kinesiotape, Active Release Techniques (ART), CranioSacral Therapy (CST) and Webster 
								Technique.  Dr. Katie and her husband, Brad, live in Lino Lakes. She enjoys being an auntie, 
								cooking/trying new recipes, and summer weekends at the cabin in Wisconsin.
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
}

export default StaffBios;