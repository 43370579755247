import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { scheduleLink }  from "../config";
// reactstrap components
import {
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  Nav,
  Container,
  Collapse,
  NavbarToggler
} from "reactstrap";
import { HashLink } from 'react-router-hash-link';
import { trackButtonClick } from "./GoogleAnalytics";

// css
import "./NaturallyAlignedNavbar.css";

function NaturallyAlignedNavbar(props) {
  const [collapsed, setCollapsed] = useState(props.collapsed ?? true);
  const [showFlag, setShowFlag] = useState(props.showFlag ?? true);
  const [showToggle] = useState(props.toggle ?? false);

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  }

  const collapseAtWidth = 991;

  useEffect(() => {
    const handleResize = () => {
      if(window.innerWidth < collapseAtWidth) {
        setShowFlag(false);
      } else {
        setShowFlag(true);
      }
    }

    window.addEventListener('resize', handleResize);
    
    return function cleanup()  {
      window.removeEventListener('resize', handleResize);
    };
  }, [ setCollapsed, showToggle ]);

  const renderMenu = () => {
    return (
            <Nav navbar>
              <NavItem>
                <NavLink to="/" tag={Link} id="navbar-home">
                  Home
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/about-us" tag={Link}>
                  About
                </NavLink>
              </NavItem>
              <NavItem>
                <HashLink className="nav-link" to="/#services">
                  Services
                </HashLink>
              </NavItem>
              <NavItem>
                <NavLink to="/payment-options" tag={Link}>
                  Payment
                </NavLink>
              </NavItem>
              <NavItem>
                <HashLink className="nav-link" to="/#contact">
                  Contact
                </HashLink>
              </NavItem>
              { renderFlag() }
            </Nav>
      );
  };

  const renderFlag = () => {
    if(showFlag) {
      return(
        <>
          <NavItem className="na-book-appointment-navitem">
            <div className="na-book-appointment">
              <a rel="noopener noreferrer"
                target="_blank"
                onClick={() => trackButtonClick("Schedule Appointment Flag")}
                href={scheduleLink}>
                Schedule an Appointment
              </a>
            </div>
          </NavItem>
        </>
      );
    } 
  }

  const renderToggle = () => { 
    if(showToggle) {
      return (
        <>
        <NavbarToggler onClick={toggleNavbar} className="mr na-navbar-toggler" />
        <Collapse isOpen={!collapsed} className="na-navbar-collapse" navbar>
          { renderMenu() }
        </Collapse>
        </>
      );
    } else {
      return( <>{ renderMenu() }</> );
    }
  };

  return (
    <>
      <Navbar className="na-navbar na-top navbar-transparent" color="info" expand="lg" dark>
        <Container>
          <NavbarBrand id="navbar-brand" />
          { renderToggle() }
        </Container>
      </Navbar>
    </>
  );
}

export default NaturallyAlignedNavbar;
