import React from "react";
import {scheduleLink} from "../config";
import {
	Button,
	Container,
	Row,
	Col
} from "reactstrap";

import { trackButtonClick } from './GoogleAnalytics';

// css
import "./CallToAction.css";

function CallToAction() {
	return(
		<>
			<div className="section na-section-call-to-action">
				<Container>
					<Row>
						<Col>
							Are you tired of the sleepless nights tossing and turning
							trying to find a comfortable position?
							<p/>
							Do your mommy instincts tell you something is off with your kiddo? 
							<p />
							Have you been told your baby isn't in a position optimal for birth?
							<p />
							Do you have an irritable baby that you aren't sure how to soothe?
							<p />
							<div className="na-call-to-action-emphasis">LET US HELP</div>{' '}
							make your life easier so you can focus on what's most important.
						</Col>
						<Col sm="6" md={{ size: 5, offset: 1 }}>
							<Row>
								<Button href={scheduleLink} 
										onClick={() => trackButtonClick("Schedule Appointment Let Us Help")}
										target="_blank"
										rel="noopener noreferrer"
										className="na-call-to-action-button" 
										size="lg" block>
									Schedule an Appointment
								</Button>
							</Row>
							<Row>
								<div className="na-call-to-action-reach-out">
									or reach out:
								</div>
							</Row>
							<Row>
								<div className="na-call-to-action-contact-info">
									<a href="tel:651-210-5261">651.210.5261</a><br/>
									<a href="mailto:info@naturallyaligned.com?subject=Connect from website">info@naturallyaligned.com</a>
								</div>
							</Row>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	)
}

export default CallToAction;