import React from "react";

// reactstrap components
import {
	Container,
	Row,
	Col
} from "reactstrap";

// css
import "./TargetPatient.css";

function TargetPatient() {
	return(
		<>
			<div className="section na-section-target-patient">
				<Container>
					<Row>
						<Col className="na-target-text">
							<Row>
								<div className="na-block-title">
									Chiropractic Adjustments allow you to eliminate pain,
									heal faster and function better.
								</div>
								<div className="na-target-patient-copy">
									Every client is unique and every stage of life requires a 
									little different approach.  Our doctors hold several 
									certifications, advanced degrees, and endless hours of 
									continuing education in those protocols and techniques 
									specific to pregnant and pediatric populations.
								</div>
							</Row>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
}

export default TargetPatient;