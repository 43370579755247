import React, { useState, useCallback } from 'react';

// reactstrap components
import {
	Card,
	Col,
	Container,
	CardBody,
	Row,
	Collapse
} from "reactstrap";

import { FAQListData } from './FAQListData';

// css
import "./FAQList.css";

function FAQList() {
	const [ openAnswer, setOpenAnswer ] = useState(-1);

	const toggle = useCallback((i) => {
		if(openAnswer === i) {
			setOpenAnswer(-1);
		} else {
			setOpenAnswer(i);
		}
	}, [openAnswer, setOpenAnswer]);

	const faqCards = () => {
		return FAQListData.map ((d, i) => {
			return(
				<Row key={d.id}>
					<Col>
						<div className="na-faq-q" onClick={() => toggle(i)}>
							{d.q}
						</div>
						<Collapse isOpen={openAnswer === i} className="na-faqlist-collapse">
							<Card>
								<CardBody>
									<div className="na-faq-a" dangerouslySetInnerHTML={{ __html: d.a}} />
								</CardBody>
							</Card>
						</Collapse>
					</Col>
				</Row>
			)});
	}

	return(
		<>
			<div className="na-faq">
				<Container>
					<Row>
						<Col>
							<div className="na-faq-header">
								Frequently Asked Questions
							</div>
						</Col>
					</Row>
					{faqCards()}
				</Container>
			</div>
		</>
	);
}

export default FAQList;