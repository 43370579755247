import React from "react";

// reactstrap components
import {
	Container,
	Row,
	Col
} from "reactstrap";
import PricingTable from "./PricingTable";

// css
import "./PaymentOptionsAndPlans.css";

function PaymentOptionsAndPlans() {
	return (
		<>
			<div className="na-section-payment-options">
          		<Container>
            		<Row>
              			<Col className="ml-auto mr-auto">
							<div className="na-payment-options-copy">
								<div className="na-payment-options-header">
									Payment Options
								</div>
								We believe that all people deserve regular chiropractic care in order to 
								experience the highest quality of life. To make regular chiropractic care 
								achievable we offer 3 easy payment options. You can pay per visit, purchase 
								a package of visits to use as you need, or become one of our members.  The 
								membership approach is the most affordable and  convenient way to get your 
								spine checked regularly.  
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<PricingTable />
						</Col>
					</Row>
					<Row>
						<Col>
							<div className="na-payment-options-question">
								Wait. You don’t accept medical insurance?
							</div>
							At Naturally Aligned we believe chiropractic care is different from conventional
							medicine. Health insurance was designed for expensive medical events or catastrophic 
							occurrences that require medical procedures or hospitalizations. We view chiropractic 
							care, good nutrition, and steady lifestyle choices as tools to help you maintain 
							your health, keep you moving and ideally, keep you free from significant health 
							crisis. By not participating in insurance reimbursement programs our paperwork 
							is less, our overhead is lower and it allows us more time to do what we really 
							love ~ demonstrate stretches, share recipes, talk health and provide quality care on 
							your terms! {' '}
							<div className="na-payment-options-emphasis">
								You CAN use your HSA or FSA accounts!
							</div>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
}

export default PaymentOptionsAndPlans;