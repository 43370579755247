import React from "react";

import { scheduleLink } from '../config';
import { trackButtonClick } from './GoogleAnalytics';

// reactstrap components
import {
	Button,
	Container,
	Row,
	Col
} from "reactstrap";

// css
import "./StriveAndSchedule.css";

function StriveAndSchedule() {
	return(
		<>
			<div className="section na-section-strive">
          		<Container>
            		<Row>
              			<Col className="ml-auto mr-auto">
                			We strive to provide a space for you to be nutured,
							valued and 
							<div className="na-empowered">{' '}empowered{' '}</div>
							during motherhood.  We truly believe:
							<p />
							<div className="na-life-is-better">
								life is better when you&apos;re aligned
							</div>
              			</Col>
						<Col className="mls-auto mr-auto na-strive-schedule">
							<Button href={scheduleLink}
									onClick={() => trackButtonClick("Schedule Appointment Strive")}
									target="_blank"
									rel="noopener noreferrer"
									className="na-strive-button"
									size="lg" block>
									Schedule an Appointment
							</Button>
						</Col>
					</Row>
				</Container>
			</div>
		</>
	);
}

export default StriveAndSchedule;