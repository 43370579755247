import React from 'react';
import ReactGA from 'react-ga4';

// config
import { googleAnalyticsCodeDev, googleAnalyticsCodeProd } from '../config';

let gaDebug = true;
export function initializeGoogleAnalytics() {
  switch(process.env.NODE_ENV) {
    case 'production':
      gaDebug = false;
      ReactGA.initialize(googleAnalyticsCodeProd);
      break;
    case 'test':
      ReactGA.initialize('unit test', {testMode: true});
      break;
    default:
      ReactGA.initialize(googleAnalyticsCodeDev);
      break;
  }
}

export const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = page => {
    ReactGA.set({
      debug: gaDebug,
      page,
      ...options
    });
    ReactGA.send("pageview");
  };

  const HOC = props => {
    React.useEffect(() => trackPage(props.location.pathname), [
      props.location.pathname
    ]);

    return <WrappedComponent {...props} />;
  };

  return HOC;
};

export const eventCategories = {
  buttonClick: "Button Click",
}

export const trackButtonClick = (name) => {
  ReactGA.event({
    category: eventCategories.buttonClick,
    action: name
  });
}