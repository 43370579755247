import React from 'react';

// custom components
import NaturallyAlignedNavbar from "./components/NaturallyAlignedNavbar.js";
import NaturallyAlignedHeader from "./components/NaturallyAlignedHeader.js";
import NaturallyAlignedFooter from "./components/NaturallyAlignedFooter.js";
import MaternalSection from "./components/MaternalSection.js";
import TargetPatient from "./components/TargetPatient.js";
import Staff from "./components/Staff.js";
import CallToAction from './components/CallToAction.js';
import DownloadGuide from './components/DownloadGuide.js';
import StriveAndSchedule from './components/StriveAndSchedule.js';
import ThreeServices from './components/ThreeServices.js';
import Testimonials from './components/Testimonials.js';

function Home() {

  React.useEffect(() => {
    document.body.classList.add("landing-page");
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });

  return (
    <>
      <NaturallyAlignedNavbar toggle={true} />

      <div className="wrapper">

        <NaturallyAlignedHeader />

        <MaternalSection />

        <TargetPatient />

        <ThreeServices />

        <Staff />

        <CallToAction />

        <Testimonials />

        <DownloadGuide />

        <StriveAndSchedule />
        
        <NaturallyAlignedFooter />
        
      </div>
    </>
  );
}

export default Home;
