import React from "react";

// reactstrap components
import {
	Container,
	Row,
	Col
} from "reactstrap";

// css for component
import "./PricingTable.css";

import naLogo from "../assets/img/na-logo-300-disc.png";

function PricingTable() {
	return (
		<>
			<div className="na-pricing-table">
				<Container>
					<Row>
						<Col xs={3} className="na-pricing-table-logo-col">
							<img src={naLogo} alt="Naturally Aligned" />
						</Col>
						<Col xs={9} className="na-pricing-table-header-col">
							<div className="na-pricing-table-head-1">
								Chiropractic care at an affordable price
							</div>
							<div className="na-pricing-table-head-2">
								<div className="na-pricing-table-head-2-emphasis">empowering</div>{' '}
								moms raising strong, healthy, vibrant families
							</div>
							<div className="na-pricing-table-description">
								Whether you have acute pain that needs attention or are on the path to
								lifelong wellness, Our goal, through chiropractic care, is to refine
								your ability to listen to your body so that you can find{' '}
								<div className="na-pricing-table-bold">balance</div>,{' '}
								<div className="na-pricing-table-bold">vitality</div>, and have the{' '}
								<div className="na-pricing-table-bold">ability to enjoy what&apos;s most important in your life</div>.
								With extensive training in the care of newborns, children, and perinatal populations
								we strive to positively impact the health of future generations.  With locations in{' '}
								<div className="na-pricing-table-bold">St. Paul</div>,{' '}
								<div className="na-pricing-table-bold">White Bear Lake</div> and {' '}
								<div className="na-pricing-table-bold">Eagan</div> there&apos;s always{' '}
								a time and place for an adjustment!{' '}
								<div className="na-pricing-table-italic">Choose from the following options:</div>
							</div>
						</Col>
					</Row>
					<Row className="na-pricing-table-header-row">
						<Col xs={3} className="na-pricing-table-header">
						</Col>
						<Col xs={3} className="na-pricing-table-header">
							Membership
						</Col>
						<Col xs={3} className="na-pricing-table-header">
							Package of Visits
						</Col>
						<Col xs={3} className="na-pricing-table-header">
							Pay as you Go
						</Col>
					</Row>
					<Row className="na-pricing-table-row na-pricing-table-dark-row">
						<Col className="na-pricing-table-row-header">
							New Client Fee
						</Col>
						<Col>
							$75; additional family members $40 each **
						</Col>
						<Col>
							$75; additional family members $40 each **
						</Col>
						<Col>
							$75; additional family members $40 each **
						</Col>
					</Row>
					<Row className="na-pricing-table-row">
						<Col xs={3} className="na-pricing-table-row-header">
							Price
						</Col>
						<Col xs={3}>
							$115/month for individual<br />
							$200/month for family*
						</Col>
						<Col xs={3}>
							10 visits for $375<br />
							5 visits for $215
						</Col>
						<Col xs={3}>
							$50/adjustment
						</Col>
					</Row>
					<Row className="na-pricing-table-row na-pricing-table-dark-row">
						<Col xs={3} className="na-pricing-table-row-header">
							No. of Adjustments
						</Col>
						<Col xs={3}>
							Up to 5 per month,<br />
							per person
						</Col>
						<Col xs={3}>
							One per person,<br />
							per visit
						</Col>
						<Col xs={3}>
							One per person,<br />
							per visit
						</Col>
					</Row>
					<Row className="na-pricing-table-row">
						<Col xs={3} className="na-pricing-table-row-header">
							Expiration
							<div className="na-pricing-table-tiny-font">(from date of purchase)</div>
						</Col>
						<Col xs={3}>
							Every 30 days
						</Col>
						<Col xs={3}>
							5 visits: 6 months<br />
							10 visits: 12 months
						</Col>
						<Col xs={3}>
							N/A
						</Col>
					</Row>
					<Row className="na-pricing-table-row na-pricing-table-dark-row">
						<Col xs={3} className="na-pricing-table-row-header">
							Contract
						</Col>
						<Col xs={3}>
							Minimum 3 months
						</Col>
						<Col xs={3}>
							N/A
						</Col>
						<Col xs={3}>
							N/A
						</Col>
					</Row>
					<Row>
						<Col className="na-pricing-table-footer">
							* Family is designated by those who make up your immediate family and live under the same roof.
							** New client fee for additional family members is good for 30 days.<br />
							<div className="na-pricing-table-footer-emphasis">
								Naturally Aligned Chiropractic &amp; Wellness accepts cash, personal checks, credit cards 
								and payments from HSA or FSA accounts. 2% fee added to CC payments.
							</div>

						</Col>
					</Row>

					<div className="na-pricing-branding-footer">www.NaturallyAligned.com | <a className="na-pricing-branding-footer" href="tel:651-210-5261">651.210.5261</a></div>
					<div className="na-pricing-lifeisbetter">Life is better when you're aligned</div>
				</Container>
			</div>
		</>
	)
}

export default PricingTable;