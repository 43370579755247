import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { HashRouter, Route, Switch } from 'react-router-dom';

// styles for this kit
import "./assets/css/bootstrap.min.css";
import "./assets/scss/now-ui-kit.scss";

// import custom styles
import "./assets/css/na.css";

// pages
import Home from './Home';
import AboutUs from './AboutUs';
import PaymentOptions from './PaymentOptions'; 
import FAQs from './FAQs';

// Google Analytics Component
import { withTracker, initializeGoogleAnalytics } from './components/GoogleAnalytics';

// initialize and track navigation performance
initializeGoogleAnalytics();

ReactDOM.render(
  <HashRouter hashType="noslash">
      <Switch>
        <Route exact path="/" component={withTracker(Home) } />
        <Route exact path="/about-us" component={withTracker(AboutUs) } />
        <Route exact path="/payment-options" component={withTracker(PaymentOptions) } />
        <Route exact path="/faq" component={withTracker(FAQs)} />
        <Route component={withTracker(Home)} />
      </Switch>
  </HashRouter>,
  document.getElementById('root')
  
);

// register a service worker for a PWA
// https://create-react-app.dev/docs/making-a-progressive-web-app/
serviceWorker.register();