import React from "react";
import { Parallax } from "react-parallax";

// reactstrap components
import { Container } from "reactstrap";
import { mobileWidth } from "../config";

// css
import "./Staff.css";

// image
import staffImage from "../assets/img/staff-2000.jpg";
import staffImageMobile from "../assets/img/staff-mobile.jpg";

function Staff() {

	let backgroundImage = staffImage;
	let parallaxStrength = 200;
	if(window.innerWidth < mobileWidth) {
		backgroundImage = staffImageMobile;
		parallaxStrength = 50;
	}

	return(
		<>
			<Parallax bgClassName="na-staff-image" 
					  bgImage={backgroundImage} 
					  strength={parallaxStrength}>
				<Container className="na-staff"> 
					<div className="na-staff-faces"></div>
					<div className="na-staff-emphasis">Empowering</div> moms growing strong,
					healthy, vibrant families.  At Naturally Aligned, Drs Katie, Amber and
					Kelley, view chiropractic care, good nutrition, proper supplementation,
					quality rest and movement as tools to help you regain or maintain your 
					health.  We strive to serve our patients with high quality, affordable 
					chiropractic care and lifestyle recommendations that are convenient for
					busy women in a comfortable and caring environment. 
					<p />
					Our goal is to educate and inspire people to take responsibility 
					for their health to reach their maximum health potential. Our vision 
					is to improve the lives of children suffering from health problems 
					and to inspire parents to become role models. 
				</Container>
			</Parallax>
		</>
	);
}

export default Staff;
