const FAQListData = [
	{id: 1, q: 'What is chiropractic?', 
			a: `<div class="na-faq-p">
				Chiropractic is one of the western world's three major healing professions. 
				The other two are medicine and osteopathy. Chiropractors specialize in the
				neuromuscular skeletal systems (nerves, muscles, and bone), detecting and 
				correcting vertebral misalignments of the spine which can adversely affect 
				the nervous system and cause “dis-ease” within the body. Many chiropractic
				techniques involve the use of the hands in gently restoring the vertebrae
				to their proper alignment and motion. Chiropractic is a lifestyle that more 
				and more people are choosing for the rest of their life. Many parents are 
				discovering that their children are healthier and happier when they receive
				regular chiropractic care.
				</div>`},
	{id: 2, q: 'What is a chiropractic adjustment?', 
			a: `<div class="na-faq-p">
				A chiropractic adjustment is the art of introducing a specific force in a
			   precise direction, applied to a vertebra that is out of place. The Chiropractor
			   identifies the location of the misalignment, determines the exact direction
			   and the best way to correct the positioning. The adjustment itself is a movement
			   of the spine that is done either by hand or with an instrument. The movement
			   may be quick or may be undetectable with low force techniques. The adjustment
			   assists the vertebra back into normal alignment and restores proper motion in 
			   order to relieve nerve interference.
			   </div>`},
	{id: 3, q: 'Is chiropractic safe during pregnancy?',
			a: `<div class="na-faq-p">
				ABSOLUTELY! During pregnancy most women experience pain and discomfort as their 
				bodies adapt to the many changes taking place inside. These changes not only 
				include hormonal changes but biomechanical changes as the body deals with extra 
				weight and changes to posture. Such changes can affect the spine ultimately 
				leading to complications with nerves, joints, connective tissue, ligaments, 
				posture and stability. Being free from subluxations allows you and your body 
				to function optimally. Pregnant mothers free from subluxations experience greater
				comfort, less pain, shorter labor and delivery times. As a whole these mothers
				experience vitality and health throughout their pregnancy and are better prepared
				to deal with the stress that childbirth can provide.
				</div>
				<div class="na-faq-p">
				Chiropractic adjustments do not harm the pregnant mother or her unborn child. 
				In fact, maintaining a healthy spine and nervous system throughout pregnancy
				can prevent further postural problems that might occur during and following 
				childbirth.
				</div>`},
	{id: 4, q: 'Can chiropractic adjustments aid with breech presentations?',
			a: `<div class="na-faq-p">
				Chiropractic care benefits all aspects of your body's ability to be healthy.
				This is accomplished by working with the nervous system--the communication 
				system between your brain and body. Doctors of Chiropractic work to correct 
				spinal, pelvic and cranial misalignments. When misaligned, these structures 
				create an imbalance in surrounding muscles and ligaments. Sacral misalignment 
				causes the tightening and torsion of specific pelvic muscles and ligaments. 
				It is these tense muscles and ligaments and their constraining effect on the 
				uterus which prevents the baby from comfortably assuming the best possible
				position for birth. The Webster Technique is defined as a specific chiropractic 
				analysis and adjustment that reduces interference to the nerve system and 
				facilitates biomechanical balance in pelvic structures, muscles and ligaments.
				This has been shown to reduce the effects of intrauterine constraint, allowing
				the baby to get into the best possible position for birth.
				</div>`},
	{id: 5, q: 'Is chiropractic safe for kids?', 
			a: `<div class="na-faq-p">
				Yes! Specific chiropractic adjustments are very safe since care is individually
				based after a complete health history and chiropractic examination has been
				performed. Then, depending on the state of health and age of the child, an
				appropriate technique will be utilized.
				</div>
				<div class="na-faq-p">
				In the pediatric population a peer reviewed study of the literature 
				(Pistolese R. JVSR 2(2) 1998) was performed from 1977 to 1998 and found the 
				risk to neurological or vascular complications from chiropractic care to be
				1/250 million. It is important to compare this with something like the risk
				of serious adverse injury from taking an aspirin at 4/1000.
				</div>
				<div class="na-faq-p">
				A New Zealand government study found that adjustments are remarkably safe.
				By avoiding drugs and risky surgery, chiropractic enjoys an excellent track record. 
				In the adult population, unfortunately anyone who manipulates and injures a
				patient gets lumped in as a "chiropractic" statistic versus keeping professions
				as separate populations. This poses a problem for the chiropractors who 
				perform specific chiropractic adjustments. Presently the cumulative risk from
				the professions who manipulate and adjust is approximated at between 1/3 million
				to 1/500,000.
				</div>` },
	{id: 6, q: `What causes spinal misalignments?`,
			a: `<div class="na-faq-p">
				There are three basic causes of subluxations: physical, emotional, and toxic 
				traumas. Physical causes could include the birth process, slips, falls, accidents,
				repetitive motions and improper lifting. Emotions such as grief, anger and fear 
				can cause subluxations. Toxic or chemical causes could include alcohol, drugs, 
				pollution, vaccines, and poor diet.
				</div>`},
	{id: 7, q: `What causes that popping sound?`,
			a: `<div class"na-faq=p">
				Lubricating fluids separate the bones of each spinal joint. Some adjusting methods 
				can produce a sound when the gas and fluids in the joint shift. It’s like opening 
				a bottle of champagne or removing a suction cup. It is NOT the bones cracking! The 
				sound is interesting, but it is not a guide to the quality or value of the adjustment.
				</div>`},
	{id: 8, q: `Can I adjust myself?`,
			a: `<div class"na-faq=p">
				No. Some people can make their joints “pop” but that is not an adjustment! Worse, 
				damage can occur by indiscriminately mobilizing a joint, causing ligaments to 
				weaken; the body will build up bone in these weakened ligaments over time to 
				stabilize itself, causing osteoarthritis. Chiropractic adjustments are specific
				and take years to master. Only the spinal joints that are “locked up” receive 
				adjustments allowing weakened muscles and ligaments to stabilize and heal.
				</div>`},

];

export { FAQListData };